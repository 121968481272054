import React from 'react';

function Projects(props) {
    
    return(
        <section id="projects" className="project-container">
            <div id="projects-carousel" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {/* <article className="carousel-item active">
                        <h3>
                            Chugging Across America
                        </h3>
                        <img src="/assets/chugging-across-america.png" className="d-block w-75" alt="Chugging Across America Start Screen" />
                        <p className="project-description">
                            This application was a team project, developed by myself and 3 others using Express, PostgreSQL, React, &amp; Redux.
                        </p>
                        <div className="action-container">
                            <a href="https://github.com/kbehnken/chugging-across-america" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px"}}>
                                View Code
                            </a>
                            <a href="https://chuggingcrossamerica.com/" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginLeft: "10px"}}>
                                Live Demo
                            </a>
                        </div>
                    </article> */}
                    <article className="carousel-item active">
                        <h2>
                            Vue
                        </h2>
                        <h3>
                            Recipe Box
                        </h3>
                        <img src="/assets/recipe-box-vue.png" className="d-block w-75" alt="Recipe Box" />
                        <p className="project-description">
                            This web application lets users view, print, add, &amp; update recipes. Additional features include the ability to keep recipes private &amp; to comment on recipes. It was built using Express, MongoDB, JWT, &amp; Vue.
                        </p>
                        <div className="action-container">
                            <a href="https://github.com/kbehnken/recipe-app-vue" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px"}}>
                                View Frontend Code
                            </a>
                            <a href="https://github.com/kbehnken/recipe-api-mongodb" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px", marginLeft: "10px"}} >
                                View Backend Code
                            </a>
                            <a href="https://recipe-box-vue.katrinabehnken.com/" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginLeft: "10px"}}>
                                Live Demo
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            React
                        </h2>
                        <h3>
                            Recipe Box
                        </h3>
                        <img src="/assets/recipe-box.png" className="d-block w-75" alt="Recipe Box" />
                        <p className="project-description">
                            This web application lets users view, add, &amp; update recipes. It was built using Express, MYSQL, React, &amp; Redux.<br /><br />
                        </p>
                        <div className="action-container">
                            <a href="https://github.com/kbehnken/recipe-app-react" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px"}} >
                                View Frontend Code
                            </a>
                            <a href="https://github.com/kbehnken/recipe-api-mysql" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px", marginLeft: "10px"}} >
                                View Backend Code
                            </a>
                            <a href="https://recipe-box.katrinabehnken.com/" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginLeft: "10px"}}>
                                Live Demo
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            React
                        </h2>
                        <h3>
                            Cultivate Community
                        </h3>
                        <img src="/assets/cultivate-community.png" className="d-block w-75" alt="Cultivate Community" />
                        <p className="project-description">
                            This web application is the result of a hackathon event hosted by Girl Develop It. It lets users send location alerts to their personal Care Team, and provides them with easy access to a directory of mental health and addiction resources. It was built using, React &amp; Redux.<br /><br />
                        </p>
                        <div className="action-container">
                            <a href="https://github.com/lulu-cao/cultivate-community" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px"}} >
                                View Frontend Code
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            React
                        </h2>
                        <h3>
                            Beach Family Pools
                        </h3>
                        <img src="/assets/bfp-portal.png" className="d-block w-75" alt="Beach Family Pools Account Summary Screen" />
                        <p className="project-description">
                            My first solo coding project - It's a hot mess, but you gotta start somewhere! This web application lets customers view their service &amp; billing history online. It was built using Express, PostgreSQL, React, &amp; Redux.<br />
                        </p>
                        <div className="action-container">
                            <a href="https://github.com/kbehnken/bfp-portal-app-react" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginRight: "10px"}} >
                                View Code
                            </a>
                            <a href="https://recipe-box.katrinabehnken.com/" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginLeft: "10px"}}>
                                Live Demo
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            WordPress
                        </h2>
                        <h3>
                            Arizona Roller Derby
                        </h3>
                        <img src="/assets/azrd.png" className="d-block w-75" alt="Recipe Box" />
                        <p className="project-description">
                            This WordPress site was built using the AllStar theme.<br /><br /><br />
                        </p>
                        <div className="action-container">
                            <a href="https://www.arizonarollerderby.com" target="_blank" rel="noopener noreferrer" className="project-links">
                                View Site
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            WordPress
                        </h2>
                        <h3>
                            Sauce Food Truck Friday
                        </h3>
                        <img src="/assets/sauce-food-truck-friday.jpg" className="d-block w-75" alt="Sauce Food Truck Friday" />
                        <p className="project-description">
                            This WordPress site was built using the Oxygen plugin.<br /><br /><br />
                        </p>
                        <div className="action-container">
                            <a href="https://saucefoodtruckfriday.com/" target="_blank" rel="noopener noreferrer" className="project-links">
                                View Site
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            WordPress
                        </h2>
                        <h3>
                            Louisville Wing Week
                        </h3>
                        <img src="/assets/wing-week-louisville.jpg" className="d-block w-75" alt="Recipe Box" />
                        <p className="project-description">
                            This WordPress site was built using the Oxygen plugin.<br /><br /><br />
                        </p>
                        <div className="action-container">
                            <a href="https://wingweeklouisville.com/" target="_blank" rel="noopener noreferrer" className="project-links">
                                View Site
                            </a>
                        </div>
                    </article>
                    <article className="carousel-item">
                        <h2>
                            Vanilla JavaScript
                        </h2>
                        <h3>
                            People Table Builder
                        </h3>
                        <img src="/assets/people-table.png" className="d-block w-75" alt="People Table" />
                        <p className="project-description">
                            This script builds a HTML table and populates it with people data. Users can sort data by column and filter people by first name.<br /><br />
                        </p>
                        <div className="action-container">
                            <a href="https://github.com/kbehnken/vanillajs/tree/master/people-table" target="_blank" rel="noopener noreferrer" className="project-links">
                                View Code
                            </a>
                            <a href="https://katrinabehnken.com/vanillajs/index.html" target="_blank" rel="noopener noreferrer" className="project-links" style={{marginLeft: "10px"}}>
                                Live Demo
                            </a>
                        </div>
                    </article>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#projects-carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon carousel-controls" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#projects-carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon carousel-controls" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </section> 
    );
}

export default Projects;